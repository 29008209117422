import type { MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { useState } from "react";
import { Header } from "~/components/header";
import { SiteFooter } from "~/components/site-footer";
import { buttonVariants } from "~/components/ui/button";
import { siteConfig } from "~/config/site";
import { cn } from "~/lib/utils";
import { setPageTitle } from "~/utils/config";
import { AppRoutes } from "~/utils/routes";

export const meta: MetaFunction = () => {
  return [
    { title: setPageTitle("Assurance") },
    {
      name: "description",
      content: "Building Blocks for a Strong Financial Outlook: Savings, Credit, Insurance.",
    },
  ];
};

export default function IndexPage() {
  const [isHovered, setIsHovered] = useState(-1);

  return (
    <div className="min-h-screen bg-background text-foreground">
      <Header />
      <main className="container mx-auto px-4">
        <section className="py-20 md:py-32">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Your Total Financial Solution
            </h1>
            <p className="text-xl md:text-2xl mb-10 text-muted-foreground">
              Building Blocks for a Strong Financial Outlook: Savings, Credit, Insurance.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link
                to={AppRoutes.LOGIN}
                className={cn(buttonVariants({ size: "lg" }), "bg-primary text-primary-foreground hover:bg-primary/90")}
              >
                Get Started
              </Link>
              <Link
                to="/blog"
                className={cn(buttonVariants({ variant: "outline", size: "lg" }), "border-primary text-primary hover:bg-primary hover:text-primary-foreground")}
              >
                News
              </Link>
            </div>
          </div>
        </section>

        <section id="features" className="py-20">
          <h2 className="text-4xl md:text-5xl font-bold text-center mb-16 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
            Our Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <FeatureCard 
                key={index} 
                {...feature} 
                isHovered={isHovered === index}
                onHover={() => setIsHovered(index)}
                onLeave={() => setIsHovered(-1)}
              />
            ))}
          </div>
        </section>

        <section id="about" className="py-20">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
              Pristinely Designed
            </h2>
            <p className="text-xl mb-10 text-muted-foreground">
              Mayicard brings together the fundamental elements of financial
              well-being under one unified platform.
            </p>
            <Link
              to={siteConfig.links.github}
              target="_blank"
              rel="noreferrer"
              className={cn(buttonVariants({ size: "lg" }), "bg-secondary text-secondary-foreground hover:bg-secondary/90")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-2"
              >
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
              </svg>
              View on GitHub
            </Link>
          </div>
        </section>
      </main>
      <SiteFooter />
    </div>
  );
}

const features = [
  {
    icon: "savings",
    title: "Savings",
    description: "Efficiently manage your savings with user-friendly tools and transparent interest calculations.",
  },
  {
    icon: "credit",
    title: "Credit",
    description: "Manage credit facilities, with flexible repayment options and real-time updates.",
  },
  {
    icon: "insurance",
    title: "Insurance",
    description: "Provide a variety of insurance products, offering peace of mind and financial protection.",
  },
  {
    icon: "investments",
    title: "Investments",
    description: "Facilitate growth of wealth with diverse investment opportunities.",
  },
  {
    icon: "authentication",
    title: "Authentication",
    description: "Seamless and secure user authentication.",
  },
  {
    icon: "subscriptions",
    title: "Subscriptions",
    description: "Manage your client base with ease.",
  },
];

function FeatureCard({ icon, title, description, isHovered, onHover, onLeave }) {
  return (
    <div 
      className={cn(
        "bg-card text-card-foreground rounded-lg p-6 shadow-md transition-all duration-300",
        isHovered && "shadow-lg -translate-y-2",
        "border border-border"
      )}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div className="flex flex-col items-center text-center">
        <div className={cn(
          "mb-4 rounded-full p-3 transition-colors duration-300",
          isHovered ? "bg-primary/20" : "bg-muted"
        )}>
          <FeatureIcon name={icon} isHovered={isHovered} />
        </div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-muted-foreground">{description}</p>
      </div>
    </div>
  );
}

function FeatureIcon({ name, isHovered }) {
  const iconClass = cn(
    "transition-colors duration-300",
    isHovered ? "text-primary" : "text-muted-foreground"
  );

  const icons = {
    savings: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iconClass}>
        <path d="M12.5 16.8c3.3-1.1 5.5-4 5.5-7.3 0-4.4-3.6-8-8-8s-8 3.6-8 8c0 3.3 2.2 6.2 5.5 7.3"/>
        <path d="M12 14v4"/>
        <path d="M10 18h4"/>
      </svg>
    ),
    credit: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iconClass}>
        <rect x="2" y="5" width="20" height="14" rx="2"/>
        <line x1="2" y1="10" x2="22" y2="10"/>
      </svg>
    ),
    insurance: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iconClass}>
        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"/>
      </svg>
    ),
    investments: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iconClass}>
        <path d="M3 3v18h18"/>
        <path d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3"/>
      </svg>
    ),
    authentication: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iconClass}>
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"/>
        <path d="M7 11V7a5 5 0 0 1 10 0v4"/>
      </svg>
    ),
    subscriptions: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={iconClass}>
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
        <line x1="2" y1="10" x2="22" y2="10"/>
      </svg>
    ),
  };

  return icons[name] || null;
}